import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

const BlogIndex = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: {order: DESC, fields: fileAbsolutePath}) {
        edges {
          node {
            frontmatter {
              description
              title
              published_date
              cat
              lang
              slug
            }
          }
        }
      }
      allContentJson {
        edges {
          node {
            blog {
              title
              back
            }
          }
        }
      }
    }
  `);

  const content = data.allContentJson.edges[0].node
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout {...props}>
      <SEO title="Blog" defer={false} />

      <div className="l-container">

        <div className="l-grid">
          <div className="l-col l-col-one-half">
          <Link to={'/'}>{content.blog.back}</Link>
          </div>
        </div>

        <div className="u-center u-pd-vt-l">
          <h1 className="c-h1 u-border-gradient u-pd-hz-l u-uppercase">{content.blog.title}</h1>
        </div>

        <ul className="c-posts-list l-grid u-pd-0 u-pd-top-xl u-mg-0">
          {posts.map(({ node }) => {
            return (
              <li className="c-posts-list__item l-col l-col-one-half@main u-pd-bottom-m u-mg-bottom-l" key={node.frontmatter.slug}>
                <a className="c-posts-list__link u-no-border" href={node.frontmatter.slug}>
                  <div className="c-posts-list__date">{node.frontmatter.published_date}</div>
                  <div className="c-posts-list__title" dangerouslySetInnerHTML={{
                      __html: node.frontmatter.lang ?
                        '[' + node.frontmatter.lang + '] ' + node.frontmatter.title
                        : node.frontmatter.title,
                    }}></div>
                  <div className="c-posts-list__tags">{node.frontmatter.cat}</div>
                </a>
              </li>
            )
          })}
        </ul>

      </div>

    </Layout>
  )
}

export default BlogIndex

